import React from 'react'
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { titleCase } from 'title-case'
import { css } from '@emotion/core'
import { Button } from 'react-bulma-components'

import SEO from '../components/SEO'
import Aside from '../components/table-of-contents/guide'
import Content from '../components/Content'
import WasThisHelpful from '../components/WasThisHelpful'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

const NavButton = ({ text, link }) => {
  return (
    <Link to={link || '/'}>
      <Button
        css={css`
          background: rgb(226, 227, 230);
          color: black;
          font-size: 14px;
          font-weight: bold;
          line-height: normal;
          visibility: ${link ? 'visible' : 'hidden'};
          font-family: 'Alternate Gothic', 'Franklin Gothic Medium',
            'Arial Narrow', sans-serif;
        `}
      >
        {text}
      </Button>
    </Link>
  )
}

export default function GuideTemplate({ data, location }) {
  const post = data.mdx
  const {
    prev,
    next,
    description,
    title,
    category,
    // resources,
  } = post.frontmatter

  // const postResource = resources || []

  const { slug } = post.fields

  const MetaData = () => {
    return (
      <>
        <h1>{titleCase(title)}</h1>
        <div
          css={css`
            display: flex;
            text-transform: uppercase;
            margin-top: 2px;
            margin-bottom: 10px;
            font-family: 'Alternate Gothic', Helvetica, sans-serif;
          `}
        >
          <span
            css={css`
              color: #cfd1d5 !important;
            `}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Nav location={location} post={post} />
      <div
        css={css`
          display: flex;
          align-items: flex-start;
          padding: 40px 0px 40px 64px;
          margin: auto;
          max-width: 1200px;

          @media (max-width: 1100px) {
            padding: 40px 20px 40px 20px;
          }

          @media (max-width: 1100px) {
            padding: 40px 20px 40px 20px;
          }
        `}
      >
        <SEO description={description} title={titleCase(title)} slug={slug} />
        <Content>
          <main id="main" data-category={category} data-slug={slug}>
            <MetaData />
            <MDXRenderer>{post.body}</MDXRenderer>
            {/* {postResource.length > 0 || (
              <div>
                <h2>Resources:</h2>
                {postResource.map((r) => {
                  return (
                    <li key={r.url}>
                      <a href={r.url}>{r.name}</a>
                    </li>
                  )
                })}
              </div>
            )} */}
            <div
              css={css`
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
              `}
            >
              <NavButton link={prev} text="Prev" />
              <NavButton link={next} text="Next" />
            </div>
          </main>
          <WasThisHelpful />
          <Footer />
        </Content>
        <Aside post={post} />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query GuideTemplate($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      tableOfContents
      frontmatter {
        title
        description
        category
        date
        prev
        next
        resources {
          name
          url
        }
      }
      fields {
        slug
      }
    }
  }
`
